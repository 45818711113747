import Calculatrice from '../assets/images/Export/IFI/CALCULATOR.png';
// import ImgNumerSlide from '../assets/images/Export/BP/ImgNumeroSlide.svg';
import Info from '../assets/images/Export/IFI/INFO.png';
import ContactImage from '../assets/images/Export/BP/contact.png'
import { restApiUri } from '../config';
import { completeDate, today } from '../utils';
import logoBase64 from './logoBase64';

// Police utilisée dans le document
export const pptFontFamily = 'Roboto';

// Couleur par défaut des textes dans le PPT
export const defaultPPTTextColor = '393939';

// Couleur Bleue dans les PPT
export const pptBlueColor = '4472C4';

const imageContactBase64 = "iVBORw0KGgoAAAANSUhEUgAAAGEAAABxCAMAAADyDj0KAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAPUExURQAAAFhcZFhcZlhcZVhcZj0CWvIAAAAEdFJOUwBAf78wcCB+AAAACXBIWXMAADLAAAAywAEoZFrbAAADr0lEQVRoQ82a4ZakOgiEO/Z9/2e+oUKbYAolkd2z3485WgpEqMnM9PhJoZTjexyHnuUiuf87ya0huYfkja9efAnNrbwtcZdb2W7U7bq/Rymfjw6kHq1SylPuH7htqU/x3Aou6fED1oSW75fkbiDmqU33DXdzN4rcdXPL8zCfQAXPTcVJHsytIEKPLxxIZ1jL3ZBl8gr2AdqZXloCkXpsOJ9A143z1fULXmArMBjlyRMuXiAqjO3DjTubmGcmkW33RNip4ASSwu7EnuBmIuNBhZ1B8KWR8SSbiXTJm9gjZLUVqLZ5RIrhLI2kI1IMHkjG88pMcyBU+dIfD1KSmcxPStVSzQTlREV3Ys/MZhoewKSU020zmcBeof4UVk2AosdLzIG1Ta2KnitkYjF4IFQ7VyLF4BXm8TApxl/ameZAUS9zJVIIVJgDpXlJFZxAMh4+sQA8kIwHN26biY+61F+L9byi0jo3ZhJ6Ax1PPEMCze7ai8vZzqhRwQaKctKLS9kcM50dEoYr3BMBpsBeof5arJrAJxZgNlP90+dL0qHyTgUeSMYD6d/cmZxAMtc8MwGnQo6ZABl1opkEYgDuiQA8EKptHpFiINAxUynjp3WQ9HgJGtg/E7A70/aoL/0401f6pVcVbOBYYHiGbTPNgSL8GH63TDRTfwbzWUmmmY66cZN0RIrBA8lcX43aqWC7TqQYfGmZOxMPJOMhUgwEzksT1TaPTT+EE0hUfmMAHkjGwycWgAdCPeq3hp5XIGWZqfTtrz+eM7FniJnO9MJ5KdFMqHmiYkXOdkY9m2msMP7ljnM9XmIKRE1AmqfHS8yB5dBRkwpWisGXRpxDpBh/aWeaA0W9zJVIIVBhDpTmJVVwAsl4+MQC8EAyHj6xAKjAR21VIsW4MVP9q27YXh1PPMMCx//y9eJylmSmcWsaikvVHDOh5omtkGOmscK4uyaa6eySHQ8q71QggaUiqu06pCwzVUS9zJVIMXggmWuemYBTIcdMgDgn0UwCMwC9MQAPhGqbR6QYztJIOiKFcNqL5smXfml31KzC+LloX/VmhbZH6InStB8q7pqpJbt8T0P70a+tmml85UOlH0OLxt01bqbpdZLruiQVbtgwE33Hhj847tNjhUgD7vs75r9vA7jbXiMSuH3Hxnz+bCBznc30/v0d8mNOpfvcsfdgcK8eN5qZMnI35q6jgsNa7ob5Zlx+0SuEpszqCeG+Ke9yK3YHVJJyK/YpXjeFcuA5/kzuXD6f/wGr6iLNkrEfYQAAAABJRU5ErkJggg==";
const imageMentionBase64 = "iVBORw0KGgoAAAANSUhEUgAAAGwAAABsCAMAAAC4uKf/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAPUExURQAAAFhcZFhcZlhcZVhcZj0CWvIAAAAEdFJOUwBAf78wcCB+AAAACXBIWXMAADLAAAAywAEoZFrbAAAEM0lEQVRoQ71a24KkKgwc7P3/bz7kIkJSwYB66mEHAklZobRne/z7AKUUHX2N8vvHOHT+IU4qwtd0h/IIfi9205/MyFWh8Uco5fhxv0Y65aqCyiHjn67sodEIxmJDSNg2G2loBIMHuPwVKTRdlgZpGIMDRIpOCBzISyslYKk8v2M0CAsZpVIgQzalgQ8I3j7eWhSa9jHuWWXBNAxpYp4sVuN65iBcFT0dVRvpT/QPmgv3NALdXtFVN9MGT5WlYbAwLdEax0FA1rpAWKJhSHqrogQ8lmGPc9c6DUNcT4laiNl47IXJlv1nNKdL2fOyi444OIDDyw+WhkFC++yUfzV6Ydi8DitB5gxQlML7uvomChobKPqGMJOudMAE1OSHB+bSKQgFRPEULtf3iJsVLmRAyS5buqiTEbSwe4tBCeCTrYFWNsmwBL7Tgl5Nlu5AqbiJkeP23QglTA6sYpsMS6Bg3Ck+Tx2vQCTkXc9gsg2HUFqiieYzi5bXyaAEf4/XbUOnUdYtvASCtwxFevIdh+AbF6i1xXfIvIQKpNYW37Ajl3UXSEF7BbTTkS05BB8YaKJXsk5GCbiJTq0rTnObOkMsAVyzjS46BDeRg+CSqXgfXiMTCbCJqD22OG/U8T0oG3PBGrSybUcsgYJQmBTXMWGFDEuAagWueLzVgbbiJkanTmt7doRNFLVRa2xGmgw3kYNhZ3btKK63EqDaC5Ys6xDaFjRRJwCbdoQSRG0szBe/2S/AEiauV9CGnizlEErCTZyn2rQMGZRwd2AEW5xzdBwAS6Dg3QFYMusYD5Gw6HrBuh1pw1YTAzvOyKAErnIrzJPdOARLgJYBsNvmZJ2EUto3QakDI6zZkSX8zm89NS93YARLxteuYwcp26B5PM4Ic0rcIXYwXJrH0RSXL27nHRwZ7ZOgbLgDtGNwnY2Mvo6knzWPf0ZX50B7++L2EDtIw/R/kHpRFEo2scIWp4oTsrYkecKvoXtYMu6Ljg0GMp4wV/LACLxfxwR3iBcGMjksQl6YL27nFzDZApcn05MHGMjEGn0gA8roi9tDvADJcBMi5O3Y7yzaxpUmVuTt2HbWJz5tqljkYiU5OypZ/+caXUnDKaF5TNYx1ftMV9JwSmgOq/Q8RLvmDQFl9nnaLY9B1A5ThS1+T7b/pyFXPLSjkD1gqkjbsZI9Y6pI2/F4ylSRt+MbsGShQ97A/0nm2hja8QVYN8Z2fAF8++iY8SEZCxvN5yNvgSqbE/rMIdxEI+MjMj4eZz1nmee4XvuwZnjVIeYtFueFl8jAyzLoQU7xJ3aM3smBNfcdMnv1J+jVDtn0DaPZ6yVrhzZ9Xer+LRYmS0ib0qTfleESk83TnuVpBHKzo5zto5mAHyKjVb+gUWhZ/iXnQxqBNHKCd2hOBFreplHIufX4hObE0dQt+3kLpWp5mebv7z+4YCN8WBqQRAAAAABJRU5ErkJggg==";

// Récupération de logo
const fetchClientLogo = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = await fetch(`${restApiUri}/api/banque/${user.pole.banqueId}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    });

    return await data.json();
};

// Récuperation des éléments pour PPT personnalisé
const fetchPPTPersonnaliseElements = async () => {
    const response = await fetchClientLogo();
    const data = {
        logo: 'simulabox',
        pptPersonnalise: false
    };

    if (response) {
        const logo = response.image_64 && response.image_64 !== "" ? response.image_64 : 'simulabox';
        const pptPersonnalise = response.ppt_key;

        data.logo = logo;
        data.pptPersonnalise = pptPersonnalise;
    }

    return data;
};

// Define Slide Master
export const defineSlideMaster = async (pptx) => {
    const element = await fetchPPTPersonnaliseElements();
    const logo = element.logo;
    const isPPTPersonnalise = element.pptPersonnalise;

    // Texte en bas du document
    const objects = [
        { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.66, y: 7.74, fontSize: 8, color: '3D4760', w: 4.06, h: 0.15, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
        { text: { text: `En date du ${completeDate(today)}`, options: { x: 0.66, y: 7.89, fontSize: 8, color: '3D4760', w: 3, h: 0.14, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
        { text: { text: '', options: { fill: '44546A', x: 0.24, y: 7.73, w: 0.32, h: 0.28, } } },

        { text: { text: '', options: { fill: '4472C4', x: 0.42, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
        { text: { text: '', options: { fill: 'ED7D31', x: 0.59, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
        { text: { text: '', options: { fill: 'A5A5A5', x: 0.76, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
        { text: { text: '', options: { fill: 'FFC000', x: 0.93, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
        { text: { text: '', options: { fill: '5B9BD5', x: 1.1, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
        { text: { text: '', options: { fill: '70AD47', x: 1.27, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
    ];

    // Logo du document
    objects.push(
        {
            image: {
                x: "80%",
                y: 7.52,
                w: 1.8,
                h: 0.6,
                data: `image/png;base64,${isPPTPersonnalise ? logo : logoBase64}`
            }
        }
    )

    // Image numéro slide
    // objects.push(
    //     {
    //         image: {
    //             x: 0.26,
    //             y: "90.6%",
    //             w: 1.8,
    //             h: 1.8,
    //             path: ImgNumerSlide
    //         }
    //     }
    // )

    // Layout
    pptx.defineLayout({ name: 'A4', width: 11.70, height: 8.26 });
    pptx.layout = 'A4';

    // Slide Master avec numérotation de page
    pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        bkgd: "FFFFFF",
        objects: objects,
        slideNumber: {
            x: 0.24,
            y: 7.73,
            w: 1,
            h: 0.28,
            bold: false,
            background: '44546A',
            color: 'FFFFFF',
            fontSize: 11,
            fontFace: pptFontFamily
        }
    });
};

// Define Contact Master
export const defineContactSlideMaster = async (pptx, value) => {
  const element = await fetchPPTPersonnaliseElements();
  const logo = element.logo;
  const isPPTPersonnalise = element.pptPersonnalise;

  // Texte en bas du document
  const objects = [
      { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.66, y: 7.74, fontSize: 8, color: '3D4760', w: 4.06, h: 0.15, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
      { text: { text: `En date du ${completeDate(today)}`, options: { x: 0.66, y: 7.89, fontSize: 8, color: '3D4760', w: 3, h: 0.14, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
      { text: { text: '', options: { fill: '44546A', x: 0.24, y: 7.73, w: 0.32, h: 0.28, } } },

      { text: { text: '', options: { fill: '4472C4', x: 0.42, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'ED7D31', x: 0.59, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'A5A5A5', x: 0.76, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'FFC000', x: 0.93, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '5B9BD5', x: 1.1, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '70AD47', x: 1.27, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },

      { text: { text: value, options: { x: 0.29, y: 0.28, fontSize: 28, color: '3D4760', w: 10.75, h: 0.38, fontFace: pptFontFamily, margin: [0.1, 0.1, 0.1, 0.1] } } },
      { text: { text: "Nous contacter", options: { x: 0.84, y: 0.71, fontSize: 22, color: '3D4760', w: 3.63, h: 0.37, fontFace: pptFontFamily, margin: [0.1, 0.1, 0.1, 0.1] } } },

      { text: { text: '', options: { fill: 'D9D9D9', x: 3.28, y: 2.44, w: 0.02, h: 2.7, } } },

      { text: { text: '', options: { fill: '4472C4', x: 3.24, y: 2.43, w: 0.1, h: 0.1, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'ED7D31', x: 3.24, y: 2.96, w: 0.1, h: 0.1, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'A5A5A5', x: 3.24, y: 3.49, w: 0.1, h: 0.1, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'FFC000', x: 3.24, y: 4, w: 0.1, h: 0.1, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '5B9BD5', x: 3.24, y: 4.52, w: 0.1, h: 0.1, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '70AD47', x: 3.24, y: 5.04, w: 0.1, h: 0.1, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
  ];

  // Logo du document
  objects.push(
      {
          image: {
              x: "80%",
              y: 7.52,
              w: 1.8,
              h: 0.6,
              data: `image/png;base64,${isPPTPersonnalise ? logo : logoBase64}`
          }
      },
      {
        image: {
            x: 0.44,
            y: 0.73,
            w: 0.26,
            h: 0.31,
            data: `image/png;base64,${imageContactBase64}`
        }
    }
  )

  // Image numéro slide
  // objects.push(
  //     {
  //         image: {
  //             x: 0.26,
  //             y: "90.6%",
  //             w: 1.8,
  //             h: 1.8,
  //             path: ImgNumerSlide
  //         }
  //     }
  // )

  // Layout
  pptx.defineLayout({ name: 'A4', width: 11.70, height: 8.26 });
  pptx.layout = 'A4';

  // Slide Master avec numérotation de page
  pptx.defineSlideMaster({
      title: "CONTACT_SLIDE",
      bkgd: "FFFFFF",
      objects: objects,
      slideNumber: {
          x: 0.24,
          y: 7.73,
          w: 1,
          h: 0.28,
          bold: false,
          background: '44546A',
          color: 'FFFFFF',
          fontSize: 11,
          fontFace: pptFontFamily
      }
  });
};

// Define Mention legale Master
export const defineLentionSlideMaster = async (pptx, value) => {
  const element = await fetchPPTPersonnaliseElements();
  const logo = element.logo;
  const isPPTPersonnalise = element.pptPersonnalise;

  // Texte en bas du document
  const objects = [
      { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.66, y: 7.74, fontSize: 8, color: '3D4760', w: 4.06, h: 0.15, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
      { text: { text: `En date du ${completeDate(today)}`, options: { x: 0.66, y: 7.89, fontSize: 8, color: '3D4760', w: 3, h: 0.14, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
      { text: { text: '', options: { fill: '44546A', x: 0.24, y: 7.73, w: 0.32, h: 0.28, } } },

      { text: { text: '', options: { fill: '4472C4', x: 0.42, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'ED7D31', x: 0.59, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'A5A5A5', x: 0.76, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'FFC000', x: 0.93, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '5B9BD5', x: 1.1, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '70AD47', x: 1.27, y: 1.14, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },

      { text: { text: value, options: { x: 0.29, y: 0.28, fontSize: 28, color: '3D4760', w: 10.75, h: 0.38, fontFace: pptFontFamily, margin: [0.1, 0.1, 0.1, 0.1] } } },
      { text: { text: "Mentions légales", options: { x: 0.84, y: 0.71, fontSize: 22, color: '3D4760', w: 3.63, h: 0.37, fontFace: pptFontFamily, margin: [0.1, 0.1, 0.1, 0.1] } } },
  ];

  // Logo du document
  objects.push(
      {
          image: {
              x: "80%",
              y: 7.52,
              w: 1.8,
              h: 0.6,
              data: `image/png;base64,${isPPTPersonnalise ? logo : logoBase64}`
          }
      },
      {
        image: {
            x: 0.44,
            y: 0.73,
            w: 0.26,
            h: 0.31,
            data: `image/png;base64,${imageMentionBase64}`
        }
    }
  )

  // Image numéro slide
  // objects.push(
  //     {
  //         image: {
  //             x: 0.26,
  //             y: "90.6%",
  //             w: 1.8,
  //             h: 1.8,
  //             path: ImgNumerSlide
  //         }
  //     }
  // )

  // Layout
  pptx.defineLayout({ name: 'A4', width: 11.70, height: 8.26 });
  pptx.layout = 'A4';

  // Slide Master avec numérotation de page
  pptx.defineSlideMaster({
      title: "MENTION_SLIDE",
      bkgd: "FFFFFF",
      objects: objects,
      slideNumber: {
          x: 0.24,
          y: 7.73,
          w: 1,
          h: 0.28,
          bold: false,
          background: '44546A',
          color: 'FFFFFF',
          fontSize: 11,
          fontFace: pptFontFamily
      }
  });
};

// Define Slide Title
export const defineTitleSlideMaster = async (pptx, bgColor) => {
  const element = await fetchPPTPersonnaliseElements();
  const logo = element.logo;
  const isPPTPersonnalise = element.pptPersonnalise;

  // Texte en bas du document
  const objects = [
      { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.66, y: 7.74, fontSize: 8, color: '3D4760', w: 4.06, h: 0.15, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
      { text: { text: `En date du ${completeDate(today)} `, options: { x: 0.66, y: 7.89, fontSize: 8, color: '3D4760', w: 3, h: 0.14, fontFace: pptFontFamily, margin: [0, 0, 0, 0]} } },
      { text: { text: '', options: { fill: '44546A', x: 0.24, y: 7.73, w: 0.32, h: 0.28, } } },
      { text: { text: '', options: { fill: bgColor ? bgColor : '44546A', x: 0, y: 0, w: 11.7, h: 0.37, } } },

      { text: { text: '', options: { fill: '4472C4', x: 1.09, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'ED7D31', x: 1.27, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'A5A5A5', x: 1.44, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'FFC000', x: 1.61, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '5B9BD5', x: 1.78, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '70AD47', x: 1.96, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
  ];

  // Logo du document
  objects.push(
      {
          image: {
              x: "80%",
              y: 7.52,
              w: 1.8,
              h: 0.6,
              data: `image/png;base64,${isPPTPersonnalise ? logo : logoBase64}`
          }
      }
  )

  // Image numéro slide
  // objects.push(
  //     {
  //         image: {
  //             x: 0.26,
  //             y: "90.6%",
  //             w: 1.8,
  //             h: 1.8,
  //             path: ImgNumerSlide
  //         }
  //     }
  // )

  // Layout
  pptx.defineLayout({ name: 'A4', width: 11.70, height: 8.26 });
  pptx.layout = 'A4';

  // Slide Master avec numérotation de page
  pptx.defineSlideMaster({
      title: "TITLE_SLIDE",
      bkgd: "FFFFFF",
      objects: objects,
      slideNumber: {
          x: 0.24,
          y: 7.73,
          w: 1,
          h: 0.28,
          bold: false,
          background: '44546A',
          color: 'FFFFFF',
          fontSize: 11,
          fontFace: pptFontFamily
      }
  });
};

export const defineTitleFicheSlideMaster = async (pptx, bgColor) => {
  const element = await fetchPPTPersonnaliseElements();
  const logo = element.logo;
  const isPPTPersonnalise = element.pptPersonnalise;

  // Texte en bas du document
  const objects = [
      { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.66, y: 7.74, fontSize: 8, color: '3D4760', w: 4.06, h: 0.15, fontFace: pptFontFamily, margin: [0, 0, 0, 0] } } },
      { text: { text: `En date du ${completeDate(today)} `, options: { x: 0.66, y: 7.89, fontSize: 8, color: '3D4760', w: 3, h: 0.14, fontFace: pptFontFamily, margin: [0, 0, 0, 0]} } },
      { text: { text: '', options: { fill: '44546A', x: 0.24, y: 7.73, w: 0.32, h: 0.28, } } },
      { text: { text: '', options: { fill: '5B9BD5', x: 0, y: 0, w: 11.7, h: 0.37, } } },

      { text: { text: '', options: { fill: '4472C4', x: 1.09, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'ED7D31', x: 1.27, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'A5A5A5', x: 1.44, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: 'FFC000', x: 1.61, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '5B9BD5', x: 1.78, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
      { text: { text: '', options: { fill: '70AD47', x: 1.96, y: 3.42, w: 0.08, h: 0.08, shape: pptx.ShapeType.roundRect, rectRadius: 1, } } },
  ];

  // Logo du document
  objects.push(
      {
          image: {
              x: "80%",
              y: 7.52,
              w: 1.8,
              h: 0.6,
              data: `image/png;base64,${isPPTPersonnalise ? logo : logoBase64}`
          }
      }
  )

  // Image numéro slide
  // objects.push(
  //     {
  //         image: {
  //             x: 0.26,
  //             y: "90.6%",
  //             w: 1.8,
  //             h: 1.8,
  //             path: ImgNumerSlide
  //         }
  //     }
  // )

  // Layout
  pptx.defineLayout({ name: 'A4', width: 11.70, height: 8.26 });
  pptx.layout = 'A4';

  // Slide Master avec numérotation de page
  pptx.defineSlideMaster({
      title: "TITLE_FICHE_SLIDE",
      bkgd: "FFFFFF",
      objects: objects,
      slideNumber: {
          x: 0.24,
          y: 7.73,
          w: 1,
          h: 0.28,
          bold: false,
          background: '44546A',
          color: 'FFFFFF',
          fontSize: 11,
          fontFace: pptFontFamily
      }
  });
};

// Page de garde
export const generatePageGarde = (
    pptx,
    slide,
    text,
    width,
    text2,
    text3,
    textProps
) => {
    // Texte de fond BLEU CLAIR
    if (text3) {
        slide.addText(text3?.value || "", {
            x: text3?.x || 0,
            y: 0.3,
            w: text3?.width || width,
            h: 0.8,
            align: text3?.align || 'center',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true,
            fill: 'B4C7E7',
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            margin: text2?.margin || []
        })
    }

    // Texte de fond BLEU CIEL
    if (text2) {
        slide.addText(text2?.value || "", {
            x: text2?.x || 0,
            y: 0.3,
            w: text2?.width || width,
            h: 0.8,
            align: text2?.align || 'center',
            fontFace: 'Roboto',
            lineSpacing: text2?.lineSpacing || undefined,
            fontSize: 14,
            color: 'FFFFFF',
            bold: text2?.bold === false ? false : true,
            fill: '4472C4',
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            margin: text2?.margin || []
        })
    }

    // Texte de fond BLEU MARINE
    slide.addText(text, {
        x: 0.6,
        y: 0.3,
        w: width,
        h: 0.8,
        align: 'center',
        fontFace: 'Roboto',
        fontSize: 14,
        color: 'FFFFFF',
        bold: true,
        fill: '28366D',
        shape: pptx.ShapeType.roundRect,
        rectRadius: 0.4,
        ...(textProps || {})
    })
}

// Titre de page (Pas l'entête) avec la barre horizontale bleue en dessous
export const generatePageTitle = (pptx, slide, label) => {
    slide.addText(label, {
        x: 0.4,
        y: 1.4,
        w: 7,
        fontSize: 13,
        bold: true,
        h: 0.4,
        fontFace: 'Roboto',
        color: '4472C4',
        valign: 'middle'
    });

    slide.addText("", {
        x: 0.5,
        y: 1.75,
        w: 0.6,
        h: 0.07,
        fill: '4472C4',
        shape: pptx.ShapeType.roundRect,
        rectRadius: 0.05,
    })
}

// Titre de première page des exports
export const generateFirstSlideTitle = (pptx, slide, title, x, y, type = 'SIMULATION', xTitle = undefined, width = undefined, subtitle) => {
    const image = type === 'SIMULATION' ? Calculatrice : Info;
    const color = type === 'SIMULATION' ? '' : '';

    const calculatriceWidth = 0.4;
    const calculatriceHeight = 0.5;
    const calculatriceX = x + 0.18;
    const calculatriceY = y + 0.18;

    const infoWidth = 0.17;
    const infoHeight = 0.42;
    const infoX = x + 0.32;
    const infoY = y + 0.22;

    if (subtitle) {
      slide.addText(subtitle, {
          x: 0.97,
          y: 2.72,
          w: 10.75,
          h: 0.34,
          fontFace: 'Roboto',
          align: 'left',
          valign: 'left',
          fontSize: 20,
          // fill: '28366D',
          color: '3D4760',
          // shape: pptx.ShapeType.roundRect,
          // rectRadius: 0.4,
          bold: true
      })
    }


    slide.addText(title, {
      x: xTitle ? xTitle : x,
      y: y,
      w: 10.75,
      h: 0.38,
      fontFace: 'Roboto',
      align: 'left',
      valign: 'left',
      fontSize: 28,
      // fill: '28366D',
      color: '3D4760',
      // shape: pptx.ShapeType.roundRect,
      // rectRadius: 0.4,
      bold: true
  })

    // slide.addImage({
    //     path: TitleImage,
    //     x: x - 0.25,
    //     y: y - 0.2,
    //     w: 1.8,
    //     h: 1.3
    // })

    // slide.addImage({
    //     path: image,
    //     x: type === 'SIMULATION' ? calculatriceX : infoX,
    //     y: type === 'SIMULATION' ? calculatriceY : infoY,
    //     w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
    //     h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
    //     fontFace: 'Roboto',
    //     fontSize: 16,
    //     color: '4472C4',
    //     align: 'center',
    //     valign: 'center',
    //     bold: true
    // })
}

// Entête de toutes les pages des exports
export const generateTitleAndSubtitle = (type, pptx, slide, title, subtitle, secondSubtitle, typeTitle) => {
    const image = type === 'SIMULATION' ? Calculatrice : Info;
    const bgColor = type === 'SIMULATION' ? '44546A' : '5B9BD5';
    const paddingLeftValue = 60;

    // Top titre
    if (typeTitle && typeTitle.text) {
      slide.addText(typeTitle.text, {
          x: 0.2,
          y: 0.2,
          w: 11.5,
          h: 0.4,
          fontFace: 'Roboto',
          fontSize: 28,
          shape: pptx.ShapeType.roundRect,
          rectRadius: 0.2,
          color: '3D4760',
          bold: false,
          margin: [0, 0, 0, 0]
      })
  }

    // Troisième titre
    if (secondSubtitle && secondSubtitle?.text && secondSubtitle?.width) {
        slide.addText(secondSubtitle.text, {
            x: 0.43,
            y: 1.43,
            w: secondSubtitle.width,
            h: 0.31,
            fill: 'FFFFFF',
            fontFace: 'Roboto',
            fontSize: 14,
            color: '4472C4',
            bold: true,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.1,
            underline: {
                color: '4472C4',
            },
            margin: [0.1, 0, 0.1, 0]
        })

        slide.addText("", {
          x: 0.43,
          y: 1.8,
          w: 0.6,
          h: 0.07,
          fill: '4472C4',
          shape: pptx.ShapeType.roundRect,
          rectRadius: 0.05,
      })
    }

    // Second titre
    if (subtitle && subtitle?.text && subtitle?.width) {
        slide.addText(subtitle.text, {
            x: subtitle.x || typeTitle?.width,
            y: 0.68,
            autoFit: true,
            w: subtitle.width,
            fill: 'BBBBBB',
            h: 0.4,
            fontFace: 'Roboto',
            fontSize: 20,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.1,
            color: 'FFFFFF',
            bold: false,
            margin: [0.1, 0, 0.1, 0]
        })
    }
    // Fin

    // Premier titre
    if (title && title.text && title.width) {
        slide.addText(title.text, {
            x: 0.29,
            y: 0.68,
            autoFit: true,
            breakLine: false,
            wrap: false,
            fill: bgColor,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.1,
            h: 0.4,
            w: title.width,
            fontFace: 'Roboto',
            fontSize: 20,
            lineSpacing: title.lineSpacing,
            color: 'FFFFFF',
            bold: false,
            margin: [0.1, 0, 0.1, 0]
        })
    }
    // Fin texte en Bleu marine

    // slide.addImage({
    //     path: TitleImage,
    //     x: 0.35,
    //     y: 0.3,
    //     w: 1.2,
    //     h: 0.85
    // })

    // Caractéristiques sur la CALCULATRICE
    const calculatriceX = 0.62;
    const calculatriceY = 0.5;
    const calculatriceWidth = 0.3;
    const calculatriceHeight = 0.4;

    // Caractéristiques sur INFO
    const infoX = 0.72;
    const infoY = 0.55;
    const infoWidth = 0.1;
    const infoHeight = 0.3;

    // slide.addImage({
    //     path: image,
    //     x: type === 'SIMULATION' ? calculatriceX : infoX,
    //     y: type === 'SIMULATION' ? calculatriceY : infoY,
    //     w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
    //     h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
    //     fontFace: 'Roboto',
    //     fontSize: 12,
    //     color: '4472C4',
    //     align: 'center',
    //     valign: 'center',
    //     bold: true
    // })
}

export const elementsPerPage = (rows, nbElementParPage) => {
    const data = [];

    rows?.map((row, index) => {
        if((index % nbElementParPage) === 0){
            let currentRow = rows.slice(index, index + nbElementParPage);

            data.push(currentRow);
        }
    })

    return data;
}

export const generateChart = (pptx, slideChart, data, x, y, w, h) => {

  let arrDataRegions = [
    {
      name: "Region 1",
      labels: ["May", "June", "July", "August"],
      values: [26, 53, 100, 75],
    },
    {
      name: "Region 2",
      labels: ["May", "June", "July", "August"],
      values: [43.5, 70.3, 90.1, 80.05],
    },
  ];

  let arrDataHighVals = [
    {
      name: "California",
      labels: ["Apartment", "Townhome", "Duplex", "House", "Big House"],
      values: [2000, 2800, 3200, 4000, 5000],
    },
    {
      name: "Texas",
      labels: ["Apartment", "Townhome", "Duplex", "House", "Big House"],
      values: [1400, 2000, 2500, 3000, 3800],
    },
  ];

  // TOP-LEFT: H/bar
  let optsChartBar1 = {
    x: 0.5,
    y: 0.6,
    w: 6.0,
    h: 3.0,
    barDir: "bar",
    plotArea: { fill: { color: "F1F1F1" } },
    // chartColors: COLORS_ACCENT,

    catAxisLabelColor: "CC0000",
    catAxisLabelFontFace: "Helvetica Neue",
    catAxisLabelFontSize: 14,
    catGridLine: { style: "none" },
    catAxisHidden: false,

    valGridLine: { color: "cc6699", style: "dash", size: 1 },
    valAxisLineColor: "44AA66",
    valAxisLineSize: 1,
    valAxisLineStyle: "dash",

    showLegend: true,
    showTitle: true,
    title: "catAxisHidden:true, valGridLine/valAxisLine:dash",
    titleColor: "a9a9a9",
    titleFontFace: "Helvetica Neue",
    titleFontSize: 11,
  };

  // TOP-RIGHT: V/col
let optsChartBar2 = {
    x: x,
    y: y,
    w: w,
    h: h,
    barDir: "col",
    plotArea: { fill: { color: "E1F1FF" } },

    dataBorder: { pt: 1, color: "F1F1F1" },
    dataLabelColor: "696969",
    dataLabelFontFace: "Arial",
    dataLabelFontSize: 11,
    dataLabelPosition: "outEnd",
    dataLabelFormatCode: "#.0",
    showValue: true,

    catAxisHidden: false,
    catGridLine: { style: "none" },
    valAxisHidden: false,
    valAxisDisplayUnitLabel: true,
    valGridLine: { style: "none" },

    showLegend: true,
    legendPos: "b",
    showTitle: false,
  };

  let optsChartBar4 = {
    x: 0.5,
    y: 0.5,
    w: 6.0,
    h: 3.5,
    chartArea: { fill: { color: "F1F1F1" } },
    plotArea: { fill: { color: "FFFFFF" } },

    barDir: "col",
    barGapWidthPct: 25,
    chartColors: ["0088CC", "99FFCC"],
    chartColorsOpacity: 50,
    valAxisMinVal: 1000,
    valAxisMaxVal: 5000,

    catAxisLabelColor: "0000CC",
    catAxisLabelFontFace: "Times",
    catAxisLabelFontSize: 11,
    catAxisLabelFrequency: 1,
    catAxisOrientation: "minMax",

    dataBorder: { pt: 1, color: "F1F1F1" },
    dataLabelColor: "696969",
    dataLabelFontFace: "Arial",
    dataLabelFontSize: 10,
    dataLabelPosition: "inEnd",
    showValue: true,

    valAxisHidden: true,
    catAxisTitle: "Housing Type",
    showCatAxisTitle: true,

    showLegend: true,
    showTitle: true,
  };
  slideChart.addChart(pptx.charts.BAR, arrDataHighVals, optsChartBar2);
}
