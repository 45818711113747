import React, { useContext, useEffect, useRef, useState } from 'react'
import {
    CSelect,
    CLabel,
    CFormGroup
} from '@coreui/react'
import { InputCurrency, InputRate, InputRateBilan, InputSelect } from '../input'
import { SimulateurContext } from '../../store/context/simulateur'
import { restApiUri } from '../../config'
import { updatePostItData, updateSimulation, updateTableRows } from '../../store/actions/simulateur'
import { getTermeColonneIndexByName, getTable1ColonneIndexByName, getTable2ColonneIndexByName, getTable3ColonneIndexByName, getTable4ColonneIndexByName } from '../../extra/simulateur'
import { currencyToSend } from '../../store/utilsFunc'
import { formatToUniqueId } from '../../utils'
import PostitService from '../../services/postitService'
import { sortPostitByIndexCase } from '../Postit/Postit'

const Hypothese = (props) => {
    const { category } = props
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext)
    const controllerRef = useRef()

    const getTxAnnuelNet = (tx_annuel_brut, frais_annuel) => {
        if (controllerRef.current) {
            controllerRef.current.abort()
        }
        const controller = new AbortController()
        controllerRef.current = controller;

        fetch(`${restApiUri}/api/PEA/getTxDeRendementAnnuelNet?txAnnuelBrut=` + tx_annuel_brut + "&fraisAnnuel=" + frais_annuel, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(resp => resp.json())
            .then(data => {
                controllerRef.current = null
                if (data.error) {
                } else {
                    if (!data.message) {
                        const tx_annuel_net = Math.round(data.txAnnuelNet * 100) / 100
                        simulateurDispatch(updateSimulation({ tx_annuel_brut, tx_annuel_net, withError: false }))
                    }
                    else {
                        if (data.message === "txAnnuelBrut < fraisAnnuel verify") {
                            simulateurDispatch(updateSimulation({ tx_annuel_brut, tx_annuel_net: 0, withError: true }))
                        }
                    }
                }
            }).catch(e => console.log(e))
    }

    const getMontantNetInvestiFondEuAndUc = (event) => {
        if (controllerRef.current) {
            controllerRef.current.abort()
        }
        const controller = new AbortController()
        controllerRef.current = controller
        const pourcentage_fond_eu = event.target.value !== "" ? event.target.value : 0;

        fetch(`${restApiUri}/api/assurance/getMontantNetInvestiFondEuAndUc?montant_investi_net=` + simulateurState.simulation.montant_investi_net + "&pourcentage_fond_eu=" + pourcentage_fond_eu, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            signal: controllerRef.current.signal
        })
            .then(resp => resp.json())
            .then(data => {
                controllerRef.current = null
                if (data.error) {
                } else {
                    simulateurDispatch(updateSimulation({ pourcentage_fond_eu, montant_investi_fond_eu: data?.montant_investi_fond_eu || 0, montant_investi_fond_uc: data?.montant_investi_fond_uc || 0 }))
                }
            }).catch(e => console.log(e))
    }

    const getTxRendementAnnuelNetFondUc = (event) => {
        if (controllerRef.current) {
            controllerRef.current.abort()
        }
        const controller = new AbortController()
        controllerRef.current = controller
        const tx_rendement_annuel_brut_uc = event.target.value !== "" ? event.target.value : 0

        fetch(`${restApiUri}/api/assurance/getTxRendementAnnuelNetFondUc?tx_redement_annuel_brut_uc=` + tx_rendement_annuel_brut_uc + "&frais_gestion_administrative_uc=" + simulateurState.simulation.frais_gestion_administrative_uc + "&frais_gestion_financier_uc=" + simulateurState.simulation.frais_gestion_financier_uc + "&assiette_frais_uc=" + simulateurState.simulation.assiette_frais_uc, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            signal: controllerRef.current.signal
        })
            .then(resp => resp.json())
            .then(data => {
                controllerRef.current = null
                if (data.error) {
                } else {
                    simulateurDispatch(updateSimulation({ tx_rendement_annuel_brut_uc, tx_rendement_net_fond_uc: (data?.tx_rednement_net_fond_uc || 0) > 0 ? data.tx_rednement_net_fond_uc : 0, withErrorUC: data?.tx_rednement_net_fond_uc < 0 }))
                }
            }).catch(e => console.log(e))
    }

    const getTxRendementAnnuelNetFondEu = (event) => {
        if (controllerRef.current) {
            controllerRef.current.abort()
        }
        const controller = new AbortController()
        controllerRef.current = controller
        const tx_rendement_annuel_brut_eu = event.target.value !== "" ? event.target.value : 0;

        fetch(`${restApiUri}/api/assurance/getTxRendementAnnuelNetFondEu?tx_redement_annuel_brut_eu=` + tx_rendement_annuel_brut_eu + "&frais_gestion_administrative_eu=" + simulateurState.simulation.frais_gestion_administrative_eu + "&frais_gestion_financier_eu=" + simulateurState.simulation.frais_gestion_financier_eu, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            signal: controllerRef.current.signal
        })
            .then(resp => resp.json())
            .then(data => {
                controllerRef.current = null
                if (data.error) {
                } else {
                    simulateurDispatch(updateSimulation({ tx_rendement_annuel_brut_eu, tx_rendement_net_fond_eu: (data?.tx_rednement_net_fond_eu || 0) > 0 ? data.tx_rednement_net_fond_eu : 0, withErrorEuro: data?.tx_rednement_net_fond_eu < 0 }))
                }
            }).catch(e => console.log(e))
    }

    const onChangeTxAnnuelBrut = (event) => {
        const tx_annuel_brut = event.target.value !== "" ? event.target.value : 0;
        getTxAnnuelNet(tx_annuel_brut, simulateurState.simulation.frais_annuel)
    }

    const Terme = ({ className, width, labelWidth, displayUnset, selectClass, selectWidth }) => (
        <CFormGroup className={`form-inline ${className || ''}`} style={{ width: width || '100%', display: 'flex', padding: 0 }}>
            <CLabel htmlFor="terme" style={{ width: labelWidth ? labelWidth : '100%', display: displayUnset ? 'unset' : 'flex', fontSize: 13, justifyContent: 'flex-start' }}>Terme de simulation</CLabel>
            <div className="d-inline-flex flex-row align-items-center" style={{ width: selectWidth ? selectWidth : 120 }}>
                <InputSelect id={`${formatToUniqueId("hypothese Terme de simulation")}-select-${category}`} className={selectClass || 'A'} style={{ width: '100%', fontSize: 13, marginRight: 0 }} onChange={event => {
                    if (['PEA', 'CT'].includes(category)) {
                        let { rows, terme } = simulateurState.simulation
                        let ligne = {}
                        Object.entries(event.target.value === 'debut' ? terme[0] : terme[1]).forEach(([key, value], index) => {
                            if (key === 'terme')
                                ligne['col1'] = event.target.value === 'debut' ? 'T. début' : 'T. fin'
                            else
                                ligne['col' + getTermeColonneIndexByName(key, category)] = key === 'tauxMoyenImposition' ? Math.round(value * 100) / 100 : value
                        })
                        rows[parseInt(rows.length - 1)] = ligne
                        simulateurDispatch(updateSimulation({ terme_simulation: event.target.value }))
                        simulateurDispatch(updateTableRows(rows))
                    }
                    else if (['AV', 'CAPI'].includes(category)) {
                        let { rows_1, rows_2, rows_3, rows_4, terme_projection_retrait, terme_projection_support_investissement, terme_projection_support_investissement_retrait, terme_projection_cumule_investissement_retrait } = simulateurState.simulation
                        let ligne_1 = {}
                        let ligne_2 = {}
                        let ligne_3 = {}
                        let ligne_4 = {}
                        Object.entries(event.target.value === 'debut' ? terme_projection_support_investissement_retrait[0] : terme_projection_support_investissement_retrait[1]).forEach(([key, value], index) => {
                            if (key === 'terme')
                                ligne_1['col1'] = ligne_2['col1'] = ligne_3['col1'] = ligne_4['col1'] = event.target.value === 'debut' ? 'T. début' : 'T. fin'
                            else
                                ligne_1['col' + getTable1ColonneIndexByName(key)] = key === 'pourcentage_en_uc' && value !== null ? Math.round(value * 100) / 100 + '%' : value
                        })
                        rows_1[parseInt(rows_1.length - 1)] = ligne_1

                        Object.entries(event.target.value === 'debut' ? terme_projection_retrait[0] : terme_projection_retrait[1]).forEach(([key, value], index) => {
                            if (key !== 'terme')
                                ligne_2['col' + getTable2ColonneIndexByName(key)] = key === 'taux_moyen_imposition' && value !== null ? Math.round(value * 100) / 100 + '%' : value
                        })
                        rows_2[parseInt(rows_2.length - 1)] = ligne_2

                        Object.entries(event.target.value === 'debut' ? terme_projection_support_investissement[0] : terme_projection_support_investissement[1]).forEach(([key, value], index) => {
                            if (key !== 'terme')
                                ligne_3['col' + getTable3ColonneIndexByName(key)] = (key === 'pourcentage_en_eu' || key === 'pourcentage_en_uc') && value !== null ? Math.round(value * 100) / 100 + '%' : value
                        })
                        rows_3[parseInt(rows_3.length - 1)] = ligne_3

                        Object.entries(event.target.value === 'debut' ? terme_projection_cumule_investissement_retrait[0] : terme_projection_cumule_investissement_retrait[1]).forEach(([key, value], index) => {
                            if (key !== 'terme')
                                ligne_4['col' + getTable4ColonneIndexByName(key)] = value === null ? '' : value
                        })
                        rows_4[parseInt(rows_4.length - 1)] = ligne_4

                        simulateurDispatch(updateSimulation({ terme_simulation: event.target.value, rows_1: rows_1, rows_2: rows_2, rows_3: rows_3, rows_4: rows_4 }))
                        simulateurDispatch(updateTableRows(rows_1))
                    }
                }} custom>
                    <option selected={simulateurState.simulation.terme_simulation === 'debut'} value="debut">Début d'année</option>
                    <option selected={simulateurState.simulation.terme_simulation === 'fin'} value="fin">Fin d'année</option>
                </InputSelect>
            </div>
        </CFormGroup>
    )

    const [montantInvestiNet, setMontantInvestiNet] = useState(0);

    useEffect(() => {
        const montantInvesti = currencyToSend(simulateurState?.simulation?.[`montant${category !== 'IS' ? '_investi' : ''}`]) || 0;
        let montantInvestiNet = 0;

        if(['AV', 'CAPI'].includes(category)){
            const droitEntreeContrat = parseFloat(simulateurState?.simulation?.droit_contrat) || 0;
            const montantFrais = Math.round((montantInvesti * droitEntreeContrat) / 100) || 0;
            montantInvestiNet = montantInvesti - montantFrais;
        }
        else{
            const fraisAnnuels = parseFloat(simulateurState?.simulation?.frais_annuel) || 0;
            const montantFrais = Math.round((montantInvesti * fraisAnnuels) / 100) || 0;
            montantInvestiNet = montantInvesti - montantFrais;

            /*const tauxAnnuelNet = parseFloat(simulateurState?.simulation?.tx_annuel_net) || 0;
            const montantCorrespondant = Math.round((montantInvesti * tauxAnnuelNet) / 100) || 0;
            montantInvestiNet = montantInvesti - montantCorrespondant;*/
        }

        setMontantInvestiNet(montantInvestiNet);
    }, [ JSON.stringify(simulateurState?.simulation) ])

    useEffect(() => {
      const fetchPins = async (type, userId, idSimulation) => {
        try {
          const pins = await PostitService.getPostitsByType(type, userId, idSimulation)

          simulateurDispatch(updatePostItData({
            ...(simulateurState?.postItData || {}),
            elements: sortPostitByIndexCase(pins)
          }))
        } catch (error) {
          console.log(error);
        }
      }
      if (simulateurState.simulation.id) {
        let simulationType = category;
        if (String(category) === 'CAPI') {
          simulationType = 'cp';
        }
        fetchPins(simulationType.toLowerCase(), localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null, simulateurState.simulation.id);
        simulateurDispatch(updatePostItData({
          ...(simulateurState?.postItData || {}),
          simulationId: simulateurState.simulation.id,
          simulationType: simulationType.toLowerCase()
        }))
      }
    }, [])

    return (
        <>
            <div className="pt-2" style={{ width: '100%', display: 'flex', alignItems: 'baseline', justifyContent: 'right', paddingRight: 20, paddingLeft: 20 }}>
                {/** Texte Hypothèses modifiables */}
                <div className='label_title' style={{ width: ['AV', 'CAPI'].includes(category) ? '15%' : '25%', display: 'flex' }}>HYPOTHÈSES MODIFIABLES</div>

                {/** Les formulaires */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: ['AV', 'CAPI'].includes(category) ? '85%' : '75%' }}>
                    {/** Partie MONTANT */}
                    <div style={{ width: '32%', marginRight: 15 }}>
                        {/** Montant investi */}
                        <CFormGroup className="form-inline" style={{ justifyContent: 'space-between' }}>
                            <CLabel htmlFor="taux_rendement" style={{ width: '39%', fontSize: 13, justifyContent: 'flex-start' }}>Montant investi</CLabel>
                            <InputCurrency
                                id={`${formatToUniqueId("hypothese Montant investi")}-${category}`}
                                className="col-lg-2"
                                value={currencyToSend(simulateurState?.simulation?.[`montant${category !== 'IS' ? '_investi' : ''}`]) || 0}
                                onChange={(event) => {
                                    const value = currencyToSend(event.target.value) || 0; // Montant investi Brut

                                    if(['AV', 'CAPI'].includes(category)){
                                        const droitEntreeContrat = parseFloat(simulateurState?.simulation?.droit_contrat) || 0;
                                        const montantFrais = Math.round((value * droitEntreeContrat) / 100) || 0;
                                        const montantNet = value - montantFrais;

                                        const montantInvestiEuro = Math.round(montantNet * ((parseFloat(simulateurState?.simulation?.pourcentage_fond_eu) || 0) / 100)) || 0;
                                        const montantInvestiUC = montantNet - montantInvestiEuro;

                                        simulateurDispatch(updateSimulation({
                                            montant_investi: value,
                                            montant_investi_net: montantNet || 0,
                                            montant_investi_fond_eu: montantInvestiEuro,
                                            montant_investi_fond_uc: montantInvestiUC
                                         }))

                                    }
                                    else{
                                        simulateurDispatch(updateSimulation({ [`montant${category !== 'IS' ? '_investi' : ''}`]: value }))
                                    }
                                }}
                            />
                        </CFormGroup>

                        {/** Montant net investi */}
                        {
                            ['AV', 'CAPI'].includes(category) && (
                                <CFormGroup className="form-inline" style={{ justifyContent: 'space-between' }}>
                                    <CLabel htmlFor="taux_rendement_net" style={{ width: '39%', fontSize: 13, justifyContent: 'flex-start' }}>Montant net investi</CLabel>
                                    <InputCurrency
                                        id={`${formatToUniqueId("hypothese Montant net investi")}-${category}`}
                                        disabled={true}
                                        className="col-lg-2"
                                        value={montantInvestiNet}
                                    />
                                </CFormGroup>
                            )
                        }
                    </div>
                    {
                        // Pour PEA et Compte titres
                        ['PEA', 'CT'].includes(category) && (
                            <>
                                {/** Partie TAUX DE RENDEMENT */}
                                <div style={{ width: '33.2%', marginRight: 15 }}>
                                    {/** Taux de rendement annuel Brut */}
                                    <CFormGroup className="form-inline" style={{ width: '100%' }}>
                                        <CLabel htmlFor="taux_rendement" style={{ width: '73%', fontSize: 13, justifyContent: 'flex-start' }}>Tx de rendement annuel brut espéré</CLabel>
                                        <div style={{ width: '27%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx de rendement annuel")}-rate-${category}`}
                                                style={{ width: '100%' }}
                                                className="A"
                                                value={simulateurState.simulation.tx_annuel_brut}
                                                onChange={onChangeTxAnnuelBrut}
                                            />
                                        </div>
                                    </CFormGroup>

                                    {/** Taux de rendement annuel Net */}
                                    <CFormGroup className="form-inline">
                                        <CLabel htmlFor="taux_rendement_net" style={{ width: '73%', fontSize: 13, justifyContent: 'flex-start' }}>Tx de rendement annuel net espéré</CLabel>
                                        <div style={{ width: '27%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx de rendement annuel net")}-rate-${category}`}
                                                style={{ width: '100%' }}
                                                disabled={true}
                                                className="A"
                                                value={![null, undefined, ''].includes(simulateurState.simulation.tx_annuel_net) ? simulateurState.simulation.tx_annuel_net : 0}
                                            />
                                        </div>
                                    </CFormGroup>
                                </div>

                                {/** Partie Terme */}
                                <div style={{ width: '27.3%' }}>
                                    <Terme labelWidth='55%' selectWidth='45%' />
                                </div>
                            </>
                        )
                    }

                    {
                        // Pour Assurance-vie et CAPI
                        ['AV', 'CAPI'].includes(category) && (
                            <>
                                {/** Partie des formulaires de Pourcentage */}
                                <div style={{ width: '45%', marginRight: 15 }}>
                                    {/** Taux de rendement fonds en € */}
                                    <CFormGroup className='form-inline' style={{ width: '100%', marginBottom: 0.5 }}>
                                        <CLabel style={{ width: '40%', fontSize: 13 }}>Tx de rendement annuel brut espéré du fonds en euros</CLabel>
                                        <div style={{ width: '22%', position: 'relative', marginRight: '1%' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx de rendement annuel brut")}-rate-${category}`}
                                                className="A"
                                                value={simulateurState.simulation.tx_rendement_annuel_brut_eu}
                                                onChange={getTxRendementAnnuelNetFondEu}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <CLabel style={{ width: '15%', fontSize: 13 }}>Tx net</CLabel>
                                        <div style={{ width: '22%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx net")}-rate-${category}`}
                                                className='A'
                                                allowNegative={true}
                                                disabled={true}
                                                value={![null, undefined, ''].includes(simulateurState.simulation.tx_rendement_net_fond_eu) ? simulateurState.simulation.tx_rendement_net_fond_eu : 0}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </CFormGroup>

                                    {/** Taux de rendement fonds en UC */}
                                    <CFormGroup className='form-inline' style={{ width: '100%' }}>
                                        <CLabel style={{ width: '40%', fontSize: 13 }}>Tx de rendement annuel brut espéré des UC</CLabel>
                                        <div style={{ width: '22%', position: 'relative', marginRight: '1%' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx de rendement UC")}-rate-${category}`}
                                                className="A"
                                                value={simulateurState.simulation.tx_rendement_annuel_brut_uc}
                                                onChange={getTxRendementAnnuelNetFondUc}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <CLabel style={{ width: '15%', fontSize: 13 }}>Tx net</CLabel>
                                        <div style={{ width: '22%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx net 2")}-rate-${category}`}
                                                className='A'
                                                disabled={true}
                                                allowNegative={true}
                                                value={![null, undefined, ''].includes(simulateurState.simulation.tx_rendement_net_fond_uc) ? simulateurState.simulation.tx_rendement_net_fond_uc : 0}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </CFormGroup>
                                </div>

                                {/** Partie de Terme de simulation */}
                                <div style={{ width: '23%' }}>
                                    {/** % investi sur le fonds en € */}
                                    <CFormGroup className="form-inline d-flex justify-content-between" style={{ width: '100%', marginBottom: 0.5 }}>
                                        <CLabel htmlFor="pourcentage_fond_eu" style={{ textAlign: 'left', fontSize: 13, width: '55%' }}>% investi sur le fonds euros</CLabel>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '45%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese investi sur le fonds euros")}-rate-${category}`}
                                                className='A'
                                                value={simulateurState.simulation.pourcentage_fond_eu}
                                                onChange={getMontantNetInvestiFondEuAndUc}
                                                style={{ width: '100%', margin: 0 }}
                                            />
                                        </div>
                                    </CFormGroup>
                                    <Terme
                                        labelWidth='55%'
                                        selectWidth='45%'
                                    />
                                </div>
                                {/*<div>
                                    <CFormGroup className="form-inline mr-3" style={{ width: 450 }}>
                                        <CLabel htmlFor="tx_rendement_annuel_brut_eu" className="p-0" style={{ justifyContent: "left", width: 200, fontSize: 13 }}>Tx de rendement annuel brut espéré du fonds en euros</CLabel>
                                        <div className="d-flex flex-row align-items-center justify-content-start pr-0" style={{ width: 250 }}>
                                            <InputRateBilan
                                                className="col-4"
                                                value={simulateurState.simulation.tx_rendement_annuel_brut_eu}
                                                onChange={getTxRendementAnnuelNetFondEu}
                                                style={{ width: 65 }}
                                            />
                                            <CLabel htmlFor="tx_rendement_net_fond_eu" className="ml-4 mr-2" style={{ fontSize: 13 }}>Tx net</CLabel>
                                            <InputRateBilan
                                                className="col-4"
                                                allowNegative={true}
                                                disabled={true}
                                                value={![null, undefined, ''].includes(simulateurState.simulation.tx_rendement_net_fond_eu) ? simulateurState.simulation.tx_rendement_net_fond_eu : 0}
                                                style={{ width: 65, marginLeft: 10 }}
                                            />
                                        </div>
                                    </CFormGroup>

                                    <CFormGroup className="form-inline mr-3" style={{ width: 450 }}>
                                        <CLabel htmlFor="tx_rendement_annuel_brut_uc" className="p-0" style={{ justifyContent: "left", width: 200, fontSize: 13 }}>Tx de rendement annuel brut espéré des UC</CLabel>
                                        <div className="d-flex flex-row align-items-center justify-content-start pr-0" style={{ width: 250 }}>
                                            <InputRateBilan
                                                className="col-4"
                                                value={simulateurState.simulation.tx_rendement_annuel_brut_uc}
                                                onChange={getTxRendementAnnuelNetFondUc}
                                                style={{ width: 65 }}
                                            // invalid={simulateurState?.simulation?.withErrorUC === true}
                                            />
                                            <CLabel htmlFor="tx_rendement_net_fond_uc" className="ml-4 mr-2" style={{ fontSize: 13 }}>Tx net</CLabel>
                                            <InputRateBilan
                                                className="col-4"
                                                disabled={true}
                                                allowNegative={true}
                                                value={![null, undefined, ''].includes(simulateurState.simulation.tx_rendement_net_fond_uc) ? simulateurState.simulation.tx_rendement_net_fond_uc : 0}
                                                style={{ width: 65, marginLeft: 10 }}
                                            />
                                        </div>
                                    </CFormGroup>
                                </div>

                                <div className="mr-3 align-self-center">
                                    <CFormGroup className="form-inline d-flex justify-content-between" style={{ width: 300 }}>
                                        <CLabel htmlFor="pourcentage_fond_eu" style={{ textAlign: 'left', fontSize: 13, width: 160 }}>% investi sur le fonds euros</CLabel>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: 128, position: 'relative' }}>
                                            <InputRateBilan
                                                className='A'
                                                value={simulateurState.simulation.pourcentage_fond_eu}
                                                onChange={getMontantNetInvestiFondEuAndUc}
                                                style={{ width: '100%', margin: 0 }}
                                            />
                                        </div>
                                    </CFormGroup>
                                    <Terme labelWidth={160} width={300} className='form-inline d-flex justify-content-between' selectClass='col-11' selectWidth={120} displayUnset />
                                </div>*/}
                            </>
                        )
                    }

                    {
                        // Pour la société à l'IS
                        category === 'IS' && (
                            <>
                                {/** Partie TAUX DE RENDEMENT */}
                                <div style={{ width: '33.2%', marginRight: 10 }}>
                                    {/** Taux de rendement annuel Brut */}
                                    <CFormGroup className="form-inline" style={{ width: '100%' }}>
                                        <CLabel htmlFor="taux_rendement" style={{ width: '73%', fontSize: 13, justifyContent: 'flex-start' }}>Tx de rendement annuel brut espéré</CLabel>
                                        <div style={{ width: '27%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx de rendement annuel brut is")}-rate`}
                                                style={{ width: '100%' }}
                                                className="A"
                                                value={simulateurState.simulation.tx_annuel_brut}
                                                onChange={onChangeTxAnnuelBrut}
                                            />
                                        </div>
                                    </CFormGroup>

                                    {/** Taux de rendement annuel net */}
                                    <CFormGroup className="form-inline" style={{ width: '100%' }}>
                                        <CLabel htmlFor="taux_rendement" style={{ width: '73%', fontSize: 13, justifyContent: 'flex-start' }}>Tx de rendement annuel net espéré</CLabel>
                                        <div style={{ width: '27%', position: 'relative' }}>
                                            <InputRate
                                                id={`${formatToUniqueId("hypothese Tx de rendement annuel net is")}-rate`}
                                                disabled={true}
                                                style={{ width: '100%' }}
                                                value={![null, undefined, ''].includes(simulateurState.simulation.tx_annuel_net) ? simulateurState.simulation.tx_annuel_net > 0 ? simulateurState.simulation.tx_annuel_net : 0 : 0}
                                                className="A"
                                            />
                                        </div>
                                    </CFormGroup>
                                </div>

                                {/** Partie Terme */}
                                {/*<div style={{ width: '27.3%' }}>
                                    <Terme labelWidth='55%' selectWidth='45%' />
                                </div>*/}
                            </>
                        )
                    }
                </div>
                {/*
                    ['AV', 'CAPI'].includes(category) &&
                    <>
                        <div>
                            <CFormGroup className="form-inline mr-3" style={{ width: 450 }}>
                                <CLabel htmlFor="tx_rendement_annuel_brut_eu" className="p-0" style={{ justifyContent: "left", width: 200, fontSize: 13 }}>Tx de rendement annuel brut espéré du fonds en euros</CLabel>
                                <div className="d-flex flex-row align-items-center justify-content-start pr-0" style={{ width: 250 }}>
                                    <InputRateBilan
                                        className="col-4"
                                        value={simulateurState.simulation.tx_rendement_annuel_brut_eu}
                                        onChange={getTxRendementAnnuelNetFondEu}
                                        style={{ width: 65 }}
                                    />
                                    <CLabel htmlFor="tx_rendement_net_fond_eu" className="ml-4 mr-2" style={{ fontSize: 13 }}>Tx net</CLabel>
                                    <InputRateBilan
                                        className="col-4"
                                        allowNegative={true}
                                        disabled={true}
                                        value={![null, undefined, ''].includes(simulateurState.simulation.tx_rendement_net_fond_eu) ? simulateurState.simulation.tx_rendement_net_fond_eu : 0}
                                        style={{ width: 65, marginLeft: 10 }}
                                    />
                                </div>
                            </CFormGroup>

                            <CFormGroup className="form-inline mr-3" style={{ width: 450 }}>
                                <CLabel htmlFor="tx_rendement_annuel_brut_uc" className="p-0" style={{ justifyContent: "left", width: 200, fontSize: 13 }}>Tx de rendement annuel brut espéré des UC</CLabel>
                                <div className="d-flex flex-row align-items-center justify-content-start pr-0" style={{ width: 250 }}>
                                    <InputRateBilan
                                        className="col-4"
                                        value={simulateurState.simulation.tx_rendement_annuel_brut_uc}
                                        onChange={getTxRendementAnnuelNetFondUc}
                                        style={{ width: 65 }}
                                    />
                                    <CLabel htmlFor="tx_rendement_net_fond_uc" className="ml-4 mr-2" style={{ fontSize: 13 }}>Tx net</CLabel>
                                    <InputRateBilan
                                        className="col-4"
                                        disabled={true}
                                        allowNegative={true}
                                        value={![null, undefined, ''].includes(simulateurState.simulation.tx_rendement_net_fond_uc) ? simulateurState.simulation.tx_rendement_net_fond_uc : 0}
                                        style={{ width: 65, marginLeft: 10 }}
                                    />
                                </div>
                            </CFormGroup>
                        </div>
                        <div className="mr-3 align-self-center">
                            <CFormGroup className="form-inline d-flex justify-content-between" style={{ width: 300 }}>
                                <CLabel htmlFor="pourcentage_fond_eu" style={{ textAlign: 'left', fontSize: 13, width: 160 }}>% investi sur le fonds euros</CLabel>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: 128, position: 'relative' }}>
                                    <InputRateBilan
                                        className='A'
                                        value={simulateurState.simulation.pourcentage_fond_eu}
                                        onChange={getMontantNetInvestiFondEuAndUc}
                                        style={{ width: '100%', margin: 0 }}
                                    />
                                </div>
                            </CFormGroup>
                            <Terme labelWidth={160} width={300} className='form-inline d-flex justify-content-between' selectClass='col-11' selectWidth={120} displayUnset />
                        </div>
                    </>
                */}

                {/*
                    ['PEA', 'CT'].includes(category) && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '75%' }}>
                            <div style={{ width: '39.53%', marginRight: 15 }}>
                                <CFormGroup className="form-inline" style={{ justifyContent: 'flex-end' }}>
                                    <CLabel htmlFor="taux_rendement" style={{ width: '35%', fontSize: 13, justifyContent: 'flex-start' }}>Montant investi</CLabel>
                                    <InputCurrency
                                        className="col-lg-2"
                                        value={0}
                                        onChange={() => { }}
                                    />
                                </CFormGroup>

                                <CFormGroup className="form-inline" style={{ justifyContent: 'flex-end' }}>
                                    <CLabel htmlFor="taux_rendement_net" style={{ width: '35%', fontSize: 13, justifyContent: 'flex-start' }}>Montant net investi</CLabel>
                                    <InputCurrency
                                        disabled={true}
                                        className="col-lg-2"
                                        value={0}
                                    />
                                </CFormGroup>
                            </div>

                            <div style={{ width: '33.2%', marginRight: 15 }}>
                                <CFormGroup className="form-inline" style={{ width: '100%' }}>
                                    <CLabel htmlFor="taux_rendement" style={{ width: '73%', fontSize: 13, justifyContent: 'flex-start' }}>Tx de rendement annuel brut espéré</CLabel>
                                    <div style={{ width: '27%', position: 'relative' }}>
                                        <InputRate
                                            style={{ width: '100%' }}
                                            className="A"
                                            value={simulateurState.simulation.tx_annuel_brut}
                                            onChange={onChangeTxAnnuelBrut}
                                        />
                                    </div>
                                </CFormGroup>

                                <CFormGroup className="form-inline">
                                    <CLabel htmlFor="taux_rendement_net" style={{ width: '73%', fontSize: 13, justifyContent: 'flex-start' }}>Tx de rendement annuel net espéré</CLabel>
                                    <div style={{ width: '27%', position: 'relative' }}>
                                        <InputRate
                                            style={{ width: '100%' }}
                                            disabled={true}
                                            className="A"
                                            value={![null, undefined, ''].includes(simulateurState.simulation.tx_annuel_net) ? simulateurState.simulation.tx_annuel_net : 0}
                                        />
                                    </div>
                                </CFormGroup>
                            </div>

                            <div style={{ width: '27.3%' }}>
                                <Terme labelWidth='55%' selectWidth='45%' />
                            </div>
                        </div>
                    )
                */}
                {/*
                    category === 'IS' &&
                    <>
                        <CFormGroup className="form-inline" style={{ width: 360 }}>
                            <CLabel htmlFor="taux_rendement" style={{ width: 230, fontSize: 13 }}>Tx de rendement annuel brut espéré</CLabel>
                            <div className="d-flex flex-row align-items-center col-4">
                                <InputRateBilan
                                    className="mr-2 col-11"
                                    value={simulateurState.simulation.tx_annuel_brut}
                                    onChange={onChangeTxAnnuelBrut}
                                    style={{ textAlign: 'right' }}
                                // invalid={simulateurState?.simulation?.withError === true}
                                />
                            </div>
                        </CFormGroup>
                        <CFormGroup className="form-inline d-flex justify-content-between" style={{ width: 360 }}>
                            <CLabel htmlFor="taux_rendement_net" style={{ width: 230, fontSize: 13 }}>Tx de rendement annuel net espéré</CLabel>
                            <div className="d-inline-flex flex-row align-items-center col-4 mx-0">
                                <InputRateBilan
                                    disabled={true}
                                    allowNegative={true}
                                    className="col-12"
                                    value={![null, undefined, ''].includes(simulateurState.simulation.tx_annuel_net) ? simulateurState.simulation.tx_annuel_net > 0 ? simulateurState.simulation.tx_annuel_net : 0 : 0}
                                    style={{ textAlign: 'right' }}
                                />
                            </div>
                        </CFormGroup>
                    </>
                */}
            </div>
        </>
    )
}

export default Hypothese
