import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCol,
  CLink,
  CDataTable,
  CRow,
  CInput,
  CButton,
  CCardHeader,
  CPagination,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CImg
} from '@coreui/react'
import Loader from '../../components/loader'
import moment from 'moment'
import { FaEye, FaTrashAlt } from 'react-icons/fa'
import trash from '../../assets/icons/svg/trash.svg'
import edit from '../../assets/icons/svg/edit.svg'
import copie from '../../assets/icons/svg/copy.svg'
import CIcon from '@coreui/icons-react'
import { restApiUri } from '../../config'
import { TheHeaderContext } from '../../store/context/theHeader'
import { updateListClientSelect } from '../../store/actions/utils/theHeader'
import { clientFromResponse, saveClient, saveConjoint, saveEnfants } from '../FicheClient/crud'
import { addToast } from '../../store/actions/utils/toast'
import { UtilsContext } from '../../store/context/utils'
import ButtonWithAction from '../../components/Button/ButtonWithAction'
import TableElements from '../../components/TableElements/TableElements'
import { dateAuFormatComplet, texteFormatCapital } from '../../utils'
import { lienFicheClient } from '../../routes'
import { DataControlContext } from '../../store/context/dataControl'

export const capitalize = (word) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const wordAsCapitalize = (word) => {
  if (word && word !== "") {
    const words = word.split(" ");
    const mots = [];

    if (words.length > 0) {
      words.map((data, index) => {
        mots.push(capitalize(data));
        if (index !== words.length - 1)
          mots.push(" ");
      })
    }

    return mots.join('');
  }
  else return "";
}

const Clients = () => {
  const history = useHistory()
  const queryPage = useLocation().search.match(/page=([1-9]+)/, '')
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
  const [page, setPage] = useState(currentPage)
  const [clients, setClients] = useState([])
  const [search, setSearch] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const [loading, setLoading] = useState(true)
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [currentClient, setCurrentClient] = useState(null);
  const { headerDispatch } = useContext(TheHeaderContext)
  const { utilsDispatch } = useContext(UtilsContext);
  const { ficheClientState, setFicheClientState } = useContext(DataControlContext);

  const columns = [
    {
      label: 'Nom',
      sorted: true,
      width: '35%',
      key: 'nom',
      formatter: (value) => <b>{value.toString().toUpperCase()}</b>,
      bold: true
    },
    {
      label: 'Prénom(s)',
      sorted: true,
      width: '17.5%',
      key: 'prenom',
      formatter: (value) => texteFormatCapital(value)
    },
    {
      label: 'Alias',
      sorted: true,
      width: '12.5%',
      key: 'alias'
    },
    {
      label: 'Date de mise à jour',
      sorted: true,
      width: '17.5%',
      key: 'updatedAt',
      formatter: (value) => moment(value).format("DD-MM-YYYY à HH:mm"),
      isDate: true
    },
    {
      label: '',
      sorted: false,
      width: '17.5%',
      key: 'col5'
    }
  ]

  const conditionOrderedList = (value1, value2) => {
    const a = JSON.parse(JSON.stringify(value1));
    const b = JSON.parse(JSON.stringify(value2));

    const a2 = (a['nom'] + (a.prenom !== "" ? ` ${a.prenom}` : "")).toLowerCase();
    const b2 = (b['nom'] + (b.prenom !== "" ? ` ${b.prenom}` : "")).toLowerCase();

    return a2 > b2 ? 1 : -1;
  }

  const clientsFetch = () => {
    setLoading(true)
    fetch(`${restApiUri}/api/client/findClient?recherche=&poleId=` + user.pole.id + "&userId=" + user.id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data.message) {
        } else if (data.mesClientsPole.length > 0 || data.autreClientPole.length > 0) {
          setLoading(true)
          let clients = data.mesClientsPole.concat(data.autreClientPole)

          clients.sort((a, b) => {
            return conditionOrderedList(a, b)
          })
          setClients(clients)
          setLoading(false)
        }
      }).catch(e => console.log(e))
  }

  const clientDelete = (clientId) => {
    const poleId = JSON.parse(localStorage.getItem('user'))?.pole?.id || null;

    setLoading(true)
    setModalConfirmation(false)
    fetch(`${restApiUri}/api/client/${clientId}?poleId=${poleId}`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        clientsFetch();
        headerDispatch(updateListClientSelect(data));
      }).catch(e => console.log(e));
  }

  const clientsFind = () => {
    setLoading(true)
    fetch(`${restApiUri}/api/client/findClient?recherche=` + search + "&poleId=" + user.pole.id + "&userId=" + user.id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data.message) {
        } else {
          setLoading(true)
          let clients = data.mesClientsPole.concat(data.autreClientPole)
          clients.sort(function (a, b) {
            var keyA = new Date(a.createdAt),
              keyB = new Date(b.createdAt);
            // Compare the 2 dates
            if (keyA < keyB) return 1
            if (keyA > keyB) return -1
            return 0
          })
          setClients(clients)
          setLoading(false)
        }
      }).catch(e => console.log(e))
  }

  const reset = () => {
    setSearch('')
    clientsFetch()
  }

  useEffect(() => {
    clientsFetch()
  }, [])

  useEffect(() => {
    currentPage !== page && setPage(currentPage)
  }, [currentPage, page])

  const pageChange = newPage => {
    currentPage !== newPage && history.push(`/clients?page=${newPage}`)
  }

  const toggleConfirmation = () => {
    setModalConfirmation(!modalConfirmation)
  }

  const cloneClient = (client) => {
    const { id } = client;

    setLoading(true);
    fetch(`${restApiUri}/api/client/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          const data = clientFromResponse(response);

          saveClient(data.conjoints)
            .then((response) => {
              if (response.id || response.message) {
                utilsDispatch(addToast({ type: 'success', title: 'VALIDATION', description: 'Le client a été dupliqué avec succès.' }))

                // Sauvegarde du Conjoint 2
                if ([1, 2, "1", "2"].includes(data.conjoints[0].situationFamiliale)) {
                  saveConjoint(data.conjoints, undefined, response.id)
                    .then((reponse) => { })
                    .catch((error) => console.error(error))
                }

                // Sauvegarde des enfants
                saveEnfants(data.enfants, undefined, response.id, 'enfant')
                  .then((reponse) => { })
                  .catch((error) => console.error(error))

                // Sauvegarde des petits-enfants
                saveEnfants(data.petitsEnfants, undefined, response.id, 'petitenfant')
                  .then((reponses) => { })
                  .catch((error) => console.error(error))

                clientsFetch();
              }
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => { setLoading(false) })
  }

  return (
    <>
      {
        loading &&
        <Loader />
      }
      <div style={{ position: 'static' }}>
        <div style={{ marginBottom: 10 }}>
          <CRow style={{ marginBottom: 5 }}>
            <CCol xl={12} lg={12} className="d-flex flex-direction-row">
              <CInput
                placeholder="Nom, prénom, alias..."
                className="mr-2"
                style={{ width: '25%', fontSize: 13 }}
                value={search}
                onChange={event => setSearch(event.target.value)}
              />
              <div style={{ marginRight: 10 }}>
                <ButtonWithAction
                  type='SEARCH-SIMULATION-CLIENT'
                  onRequestClick={clientsFind}
                />
              </div>
              <div style={{ marginRight: 10 }}>
                <ButtonWithAction
                  type='REINITIALISATION'
                  onRequestClick={reset}
                />
              </div>
              <ButtonWithAction
                type='NEW-CLIENT'
                onRequestClick={() => {
                  // On réinitialise les données du CLIENT => On remets les données par défaut
                  setFicheClientState({
                      position: 1,
                      dateEnregistrement: null,
                      conjoints: [
                        {
                          civilite: null,
                          nom: "",
                          prenom: "",
                          nomPersonnalise: "",
                          dateNaissance: "",
                          dateApproximative: false,
                          age: 0,
                          situationFamiliale: 1,
                          regime_matrimonial: 6,
                          hasDescendant: 0,
                          note: "",
                          enfants: [],
                          petitsEnfants: []
                        },
                        {
                          civilite: null,
                          nom: "",
                          prenom: "",
                          nomPersonnalise: "",
                          dateNaissance: "",
                          dateApproximative: false,
                          age: 0,
                          enfants: [],
                          petitsEnfants: []
                        }
                      ],
                      nombreEnfants: 0,
                      enfants: [],
                      nombrePetitsEnfants: 0,
                      petitsEnfants: [],
                      intituleVersion: "version1",
                      heureVersion: null,
                      dateVersion: dateAuFormatComplet(),
                      commentaireVersion: "",
                      versions: []
                  });

                  history.push(lienFicheClient)
                }}
              />
            </CCol>
          </CRow>

          <div style={{ color: "silver", fontSize: 13 }}>
            {clients.length} résultats
          </div>
        </div>
        <CCard style={{ position: 'static' }}>
          <CCardBody style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, position: 'static' }}>
            <div style={{ position: 'static', fontSize: 13 }}>
              <TableElements
                type='client'
                nombreElementsParPage={10}
                columns={columns}
                lignes={clients}
                withEditButton={true}
                withDeleteButton={true}
                onRequestRowClick={(value) => history.push(`/clients/fiche/${value.id}`)}
                onRequestEdit={(value) => history.push(`/clients/fiche/${value.id}`)}
                onRequestDelete={(value) => clientDelete(value.id)}
              />
            </div>
          </CCardBody>
        </CCard>
      </div>
    </>
  )
}

export default Clients
